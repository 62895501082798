.container {
  position: relative;
}

.emojiWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 2px solid var(--color-secondary-light);
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
  text-shadow: -1px 0 0 var(--color-secondary-light), 0 -1px 0 var(--color-secondary-light),
    1px 0 0 var(--color-secondary-light), 0 1px 0 var(--color-secondary-light);
}
